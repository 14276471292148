import { Modal, Pagination, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import style from './index.module.less';
import { useUserProvider } from '@/context/UserProvider';
import { PlanbowlArticleListModel } from '../../../types/home';
// import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ReportType } from '../CreateReportAlertContent';
import { useRouter } from 'next/router';
import { debounce } from 'lodash-es';
import { getPlanbowlSceneListData } from '@/lib/service';

const PAGE_SIZE = 6;

const SelectReportsContent = () => {
  const {
    isOpenSelectReportsAlert,
    onOpenSelectReportsAlert,
    onOpenCreateReportAlert,
    onGetStartedClick,
    onOpenLoginModal,
    onCurrentReportType,
    onCurrentReport,
    isLogin,
  } = useUserProvider();
  const { t } = useTranslation('common');
  const [page, setPage] = useState(1);
  const router = useRouter();
  const [sceneListData, setSceneListData] = useState<PlanbowlArticleListModel[]>([]);

  const paginatedData = useMemo(() => {
    if (
      page > 0 &&
      sceneListData?.length >= (page - 1) * PAGE_SIZE &&
      sceneListData?.length <= (page + 1) * PAGE_SIZE
    ) {
      return sceneListData?.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    } else {
      return [];
    }
  }, [sceneListData, page]);

  const getReportSceneData = () => {
    getPlanbowlSceneListData(router.locale)
      .then((res) => {
        setSceneListData(res?.data);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const debounceSceneData = debounce(() => getReportSceneData(), 500);

  useEffect(() => {
    debounceSceneData();
  }, []);

  const selectPeports = useCallback(
    (isTop?: boolean) => {
      if (isTop) {
        return (
          <div className={style.reporItemAll} onClick={() => onDashboardClick(ReportType.COMPLEX)}>
            <div className={style.reportTitle}>{t('ALL-IN-ONE EMERGENCY PREP')}</div>
          </div>
        );
      }
      return paginatedData?.map((item) => (
        <div
          key={item?.name}
          className={style.reportItem}
          onClick={() => onDashboardClick(ReportType.SINGLE_SCENE, item)}
          style={{ backgroundImage: `url(${item?.image})` }}
        >
          <div className={style.reportName}>{item?.name.replace(',', '')}</div>
          <div className={style.model}>
            <div className={style.btn}>{t('Get Started')}</div>
          </div>
        </div>
      ));
    },
    [paginatedData, isLogin],
  );

  const onDashboardClick = (reportType: ReportType, report?: PlanbowlArticleListModel) => {
    if (isLogin) {
      onOpenSelectReportsAlert(false);
      onCurrentReportType(reportType);
      onOpenCreateReportAlert(true);
      onCurrentReport(report || null);
    } else {
      onGetStartedClick('none');
      onOpenLoginModal(true);
    }
  };

  return (
    <Modal
      onCancel={() => onOpenSelectReportsAlert(false)}
      open={isOpenSelectReportsAlert}
      footer={null}
      width={870}
      destroyOnClose
      afterClose={() => {
        setPage(1);
      }}
    >
      <div className={style.content}>
        <h3>{t('Choose Your Guide Type')}</h3>
        <div className={style.synthesisReport}>
          <div className={style.title}>{t('ALL-IN-ONE EMERGENCY PREP')}</div>
          <div className={style.reportList}>{selectPeports(true)}</div>
        </div>
        <div className={style.synthesisReport}>
          <div className={style.title}>{t('SINGLE-THREAT READINESS')}</div>
          <div className={`${style.reportList} ${style.reportData}`}>{selectPeports()}</div>
        </div>
        <Pagination
          onChange={(page) => setPage(page)}
          current={page}
          pageSize={PAGE_SIZE}
          total={sceneListData?.length}
        />
      </div>
    </Modal>
  );
};

export default SelectReportsContent;
